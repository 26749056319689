<script lang="ts">
    import SquareButton from "~/ui/lib/SquareButton";
    import MdAirlineSeatReclineNormal from "svelte-icons/md/MdAirlineSeatReclineNormal.svelte";
    import { globalEvents } from "~/events/globalEvents";
  </script>
  
  <SquareButton
    on:click={() => globalEvents.emit("sit-ground")}
    padding={0}
    Icon={MdAirlineSeatReclineNormal}
  />
  