<script lang="ts">
    import { fade } from "svelte/transition";
    import { _ } from "~/i18n";
  
    import { AV_ENABLED, CROSS_FADE_DURATION } from "~/config/constants";
    import SeatButton from "./ButtonControls/SeatButton.svelte";

    import { onMount } from "svelte";
  
   
    let overlayCenterEl, playButtonsEl;
   
    onMount(() => {
      const resizeObserver = new ResizeObserver(() => {
        const parentRect = overlayCenterEl.getBoundingClientRect();
        const childRect = playButtonsEl.getBoundingClientRect();
        const x = Math.max(0, (childRect.width - parentRect.width) / 2);
        playButtonsEl.style.transform = `translateX(${x}px)`;
      });
      resizeObserver.observe(overlayCenterEl);
      return () => resizeObserver.unobserve(overlayCenterEl);
    });
  </script>
  
 


    <overlay-left-center
    transition:fade={{ duration: CROSS_FADE_DURATION }}
    bind:this={overlayCenterEl}
  >
    <play-left-buttons class="interactive" bind:this={playButtonsEl}>
 


        <!-- 用户 -->
    
        <SeatButton />
        <!-- TODO: if wrapped in Tooltip, tooltip flickers; Overlay.tooltips.profile -->
    
      </play-left-buttons>
  </overlay-left-center>
  
  <style>
 

    overlay-left-center {
      position: fixed;
      z-index: 3;
      bottom: 90px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      pointer-events: none;
                  -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Chrome/Safari/Opera */
      -khtml-user-select: none; /* Konqueror */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently not supported by any browser */
    }
    play-left-buttons {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 4px;
      pointer-events: all;
                  -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Chrome/Safari/Opera */
      -khtml-user-select: none; /* Konqueror */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently not supported by any browser */
    }
  
  
    @media screen and (max-width: 480px) {
 
    overlay-left-center {
        /* Unfortunately, there is a trade-off here; if we enable overflow-x,
         * tooltips will be hidden from view. Best we can do for now is to
         * only disable tooltips when necessary
         */
        overflow-x: auto;
                    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Chrome/Safari/Opera */
      -khtml-user-select: none; /* Konqueror */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently not supported by any browser */
      }
    }
  </style>
  