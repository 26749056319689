<script lang="ts">
    import RectButton from "~/ui/lib/RectButton";
    import IoIosArrowForward from "svelte-icons/io/IoIosArrowForward.svelte";
    import { globalEvents } from "~/events/globalEvents";
  </script>
  
  <RectButton
    on:click={() => globalEvents.emit("camera-rotate-right")}
    padding={0}
    Icon={IoIosArrowForward}
  />
  