<script lang="ts">
  import type { State } from "~/main/ProgramTypes";

  // Debug UI
  import DebugPane from "~/ui/Debug/DebugPane";

  import CenterCamera from "~/ui/CenterCamera";
  import Toolbar from "~/ui/Build/Toolbar";
  import BuildPanel from "~/ui/Build/BuildPanel";

  import { worldUIMode } from "~/stores";
  import { debugMode } from "~/stores/debugMode";
  import { centerCameraVisible } from "~/stores/centerCameraVisible";
  import { showCenterButtons } from "~/stores/showCenterButtons";
  import { permits } from "~/stores/permits";

  import CenterButtons from "./CenterButtons.svelte";
  import SideButtons from "./SideButtons.svelte";
  import ActionButtons from "./ActionButtons.svelte";


  
  import DialogStack from "./DialogStack.svelte";
  import PauseAutomatically from "./PauseAutomatically.svelte";
  import TouchControls from "./TouchControls.svelte";
  import gamepadImg from "./gamepad.png";

  
  export let dispatch; // Program dispatch
  export let state: State;

  let buildMode = false;
  $: buildMode = $permits.includes("edit") && $worldUIMode === "build";

  let touchControls;
  $: (window as any).touchControls = touchControls;
</script>

<!-- Pause game if participant is not focused on window, to save CPU/GPU resources -->
<PauseAutomatically />

<DialogStack />

{#if $centerCameraVisible}
  <CenterCamera />
{/if}

<overlay class:open={buildMode}>
  <overlay-panel class="interactive">
    {#if buildMode}
      <BuildPanel />
    {/if}
  </overlay-panel>

  <overlay-content>
    <r-toolbar-wrapper>
      {#if $debugMode !== "hidden"}
        <DebugPane
          {state}
          expanded={$debugMode === "expanded"}
          on:minimize={({ detail }) => {
            $debugMode = detail === "expanded" ? "minimal" : "expanded";
          }}
        />
      {/if}
      {#if buildMode}
        <Toolbar />
      {/if}
    </r-toolbar-wrapper>
  </overlay-content>
</overlay>

<TouchControls bind:this={touchControls} />

<overlay_portrait><img src={gamepadImg} /></overlay_portrait>


{#if $showCenterButtons}
  <CenterButtons {dispatch} />
  
  <SideButtons  />
  <ActionButtons  />

{/if}

<style>
  @media screen and (orientation:landscape) {

  img{
    position: absolute;
    bottom: 75px;
  width: 175px;
  height: 50px;
    z-index: 3;
    pointer-events: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
  }
  overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;

    pointer-events: none;

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
        -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
  }
  overlay_portrait {
  position: fixed;
  top: 0px;
  left: 50px;
  width: 100%;
  height: 100%;
  z-index: 3;
  justify-content: left;
  pointer-events: none;

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
}
@media screen and (orientation:portrait) {
img{
  position: absolute;
  bottom: 75px;
  width: 175px;
  height: 50px;
  z-index: 3;
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Chrome/Safari/Opera */
-khtml-user-select: none; /* Konqueror */
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;

  pointer-events: none;

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
overlay_portrait {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;
  justify-content: center;
  pointer-events: none;

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
}


}
  @media screen and (max-width: 480px) {
    overlay {
      display: none;
          -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
    }
  }
  overlay :global(button) {
    pointer-events: all;
  }
  overlay-panel {
    display: flex;
    height: 100%;
    width: 0px;
  }
  overlay.open overlay-panel {
    width: 300px !important;
  }
  overlay-content {
    display: flex;
  }

  r-toolbar-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: min-content;
  }

  r-toolbar-wrapper > :global(*) {
    margin-top: 2px;
    margin-right: 2px;
  }
</style>
